<template>
  <vue-progress-bar class="progressbar" />
  <Toast />
  <router-view :settings=settings />
</template>

<script>
export default {
  data(){
    return {
      settings: {
        general: {
          site_name: process.env.VUE_APP_NAME
        },
      },
    }
  },
  computed: {
    favicon() {
        return (this.settings.general.favicon) ? process.env.VUE_APP_ASSETS_FOLDER + '/' + this.settings.general.favicon : process.env.VUE_APP_DEFAULT_FAVICON;
    }
  },
  beforeCreate(){
		this.$e.on('getSettings', (reset = false) => {
        this.$store.dispatch('getSettings', reset).then((response) => {
          this.settings = response.data;
          $("link[rel='icon']").attr('href', this.favicon)
        });
    })
    
    this.$e.on('updateTitle', (data) => {
      document.title = data;
    });
	},
  created(){
    this.$e.emit('getSettings', true);
  },
  mounted(){
    // this.setting = this.$store.getters.setting;
  }
}
</script>

<style lang="scss">
@import './App.scss';

.progressbar {
  background: linear-gradient(to right, aqua, yellow, red);
}
</style>
