import moment from 'moment'

export default {
	install(app) {
        app.config.globalProperties.$filter_date = (data) => {
            return moment(data).locale('id').format('LL');
        };
        app.config.globalProperties.$convert_date = (data) => {
            return moment(data).format('YYYY-MM-DD');
        };
        app.config.globalProperties.$filter_dateTime = (data) => {
            return moment(data).locale('id').format('LLL');
        };
        app.config.globalProperties.$convert_dateTime = (data) => {
            return moment(data).format('HH:mm:ss');
        };
        app.config.globalProperties.$filter_currency = (data) => {
            return (data) ? data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
        };
        app.config.globalProperties.$filter_text_limit = (data, limit) => {
            const text = data.replace(/<\/?[^>]+(>|$)/gi, "");
            return text.split(" ").slice(0, limit).join(" ");
        };
    }
}