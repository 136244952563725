import { createStore } from 'vuex';
import { http, httpAuth } from './http';

const store = createStore({
    state: {
        setting: {
            general: {
                name: 'MyApp',
                logo: process.env.VUE_APP_DEFAULT_LOGO,
                favicon: process.env.VUE_APP_DEFAULT_FAVICON,
            },
            other: {},
        }
    },
    getters: {
        settings(){
            return JSON.parse(localStorage.getItem('settings'));
        },
        // get access token in local storage
        guard(){
            return JSON.parse(localStorage.getItem('guard'));
        },
        // check user Authentication
        isAuth(state, getters){
            return (getters.guard && getters.guard.access_token) ? true : false;
        },
        // get user credentials in local storage
        userCredentials(){
            return JSON.parse(localStorage.getItem('user_credentials'));
        },
        // get user regional in local storage
        regionalUser(state, getters){
            return getters.userCredentials?.user?.regional;
        },
        // check if user is regional
        isRegionalUser(state, getters){
            return (getters.regionalUser && getters.userCredentials?.roles?.includes(getters.regionalUser?.role?.name)) ? true : false;
        },
    },
    mutations: {
        // store access token in local storage
        setGuard(state, data){
            localStorage.setItem('guard', JSON.stringify(data));
        },
        // clear local storage when logout
        logout() {
            localStorage.removeItem('guard');
            localStorage.removeItem('user_credentials');
        },
    },
    actions: {
        // get app setting and store it in local storage
        getSettings(store, reset = false){
            return new Promise((resolve, reject) => {
                if(!localStorage.getItem('settings') || reset){
                    http.get('/api/get/settings')
                    .then((response) => {
                        localStorage.setItem('settings', JSON.stringify(response.data));
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                } else {
                    const response = { data: store.getters.settings };
                    resolve(response);
                }
            })
        },
        // get user credentials and store it in local storage
        getUserCredentials(){
            return new Promise((resolve, reject) => {
                httpAuth.get('/api/auth/get/user-credentials')
                .then((response) => {
                    const userCredentials = JSON.stringify(response.data);
                    localStorage.setItem('user_credentials', userCredentials);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                })
            })
        },
    }
})

export default store;