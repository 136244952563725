import store from './store';

// has permissions
const can = function(value){
	const permissions = store.getters.userCredentials.permissions;
	let _return = false;
	if(value.includes('|')){
		value.split('|').forEach(function (item) {
			if(permissions.includes(item.trim())){
				_return = true;
			}
		});
	}else if(value.includes('&')){
		_return = true;
		value.split('&').forEach(function (item) {
			if(!permissions.includes(item.trim())){
				_return = false;
			}
		});
	}else{
		_return = permissions.includes(value.trim());
	}
	return _return;
}

// has role
const is = function(value){
	let roles = store.getters.userCredentials.roles;
	let _return = false;
	if(value.includes('|')){
		value.split('|').forEach(function (item) {
			if(roles.includes(item.trim())){
				_return = true;
			}
		});
	}else if(value.includes('&')){
		_return = true;
		value.split('&').forEach(function (item) {
			if(!roles.includes(item.trim())){
				_return = false;
			}
		});
	}else{
		_return = roles.includes(value.trim());
	}
	return _return;
}

// install in vue
const permissions = {
	install(app) {
		app.config.globalProperties.$can = can
		app.config.globalProperties.$is = is;
	}
}

export {
	permissions as default,
	can,
	is
}