import mitt from 'mitt'

const emitter = mitt();

export default {
	install(app) {
        app.config.globalProperties.$e = emitter;
        // app.config.globalProperties.$e_on = (...args) => emitter.on(...args);
        // app.config.globalProperties.$e_once = (...args) => emitter.once(...args);
        // app.config.globalProperties.$e_off = (...args) => emitter.off(...args);
        // app.config.globalProperties.$e_emit = (...args) => emitter.emit(...args);
    }
}