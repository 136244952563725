import Tinymce from "@tinymce/tinymce-vue";

const Editor = {
    install(app) {
        app.component('Editor', Tinymce);
        app.config.globalProperties.$tinymce_api_key = process.env.VUE_APP_TINYMCE_API_KEY;
    }
}

export default Editor
