import axios from 'axios';
import axiosAuth from 'axios';
import Form from 'vform';
import FormAuth from 'vform';
import store from './store';

// config axios base url
const http = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
});
const httpAuth = axiosAuth.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
});

// config axios2 auth token in header
httpAuth.interceptors.request.use(function (config) {
    const guard = store.getters.guard;
    if (store.getters.isAuth) {
        config.headers['Authorization'] = `${guard.token_type} ${guard.access_token}`;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});
// handle axios2 error response if unauthenticated
httpAuth.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if(error.response.status == 401){
        store.commit('logout');
        window.location = '/auth/login';
    }
    return Promise.reject(error);
});

// set axios as default form http request
Form.axios = http;
FormAuth.axios = httpAuth;

// install in vue
const Http = {
    install(app) {
        // set axios as global property
        app.config.globalProperties.$http = http;
        app.config.globalProperties.$httpAuth = httpAuth;

        // set form as global property
        app.config.globalProperties.$Form = Form;
        app.config.globalProperties.$FormAuth = FormAuth;
    }
}

export {
    Http as default,
    http,
    httpAuth,
    Form,
    FormAuth
}