import { createRouter, createWebHistory } from 'vue-router';
import store from './store';
import { can as _can, is as _is } from './permission';

const routes = [
    {
        path: '/',
        component: () => import('@/landing/_Container.vue'),
        children: [
            {
                path: '',
                component: () => import('@/landing/Home.vue'),
            },
            {
                path: '/p/:id/:slug',
                component: () => import('@/landing/Post.vue'),
                props: true
            },
            {
                path: '/kegiatan',
                component: () => import('@/landing/KegiatanList.vue'),
            },
            {
                path: '/k/:id/:slug',
                component: () => import('@/landing/Kegiatan.vue'),
                props: true
            },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/landing/404.vue'),
    },
    {
        path: '/login',
        redirect: '/auth/login',
    },
    {
        path: '/register-member',
        redirect: '/auth/register-member',
    },
    {
        path: '/auth',
        redirect: '/auth/login',
        component: () => import('@/auth/_Container.vue'),
        beforeEnter: (to, from, next) => isUnauth(next),
        children: [
            {
                path: 'login',
                component: () => import('@/auth/Login.vue'),
            },
            {
                path: 'register-member',
                component: () => import('@/auth/RegisterMember.vue'),
            },
        ],
    },
    {
        path: '/dashboard',
        redirect: '/dashboard/main',
        component: () => import('@/dashboard/_Container.vue'),
        beforeEnter: (to, from, next) => isAuth(next),
        children: [
            {
                path: '/:pathMatch(.*)*',
                component: () => import('@/dashboard/404.vue'),
            },
            {
                path: '403',
                component: () => import('@/dashboard/403.vue'),
            },
            {
                path: 'main',
                component: () => import('@/dashboard/Main.vue'),
            },
            {
                path: 'slideshow',
                component: () => import('@/dashboard/Slideshow.vue'),
                beforeEnter: (to, from, next) => can(next, 'slideshow-list'),
            },
            {
                path: 'category',
                component: () => import('@/dashboard/Category.vue'),
                beforeEnter: (to, from, next) => can(next, 'category-list'),
            },
            {
                path: 'posts',
                component: () => import('@/dashboard/Posts.vue'),
                beforeEnter: (to, from, next) => can(next, 'post-list'),
            },
            {
                path: 'pages',
                component: () => import('@/dashboard/Pages.vue'),
                beforeEnter: (to, from, next) => can(next, 'page-list'),
            },
            {
                path: 'post-new',
                component: () => import('@/dashboard/PostForm.vue'),
                beforeEnter: (to, from, next) => can(next, 'post-add'),
            },
            {
                path: 'page-new',
                component: () => import('@/dashboard/PostForm.vue'),
                beforeEnter: (to, from, next) => can(next, 'page-add'),
            },
            {
                path: 'post-edit/:id',
                component: () => import('@/dashboard/PostForm.vue'),
                props: true,
                beforeEnter: (to, from, next) => can(next, 'post-edit'),
            },
            {
                path: 'page-edit/:id',
                component: () => import('@/dashboard/PostForm.vue'),
                props: true,
                beforeEnter: (to, from, next) => can(next, 'page-edit'),
            },
            {
                path: 'account',
                component: () => import('@/dashboard/Account.vue'),
                beforeEnter: (to, from, next) => can(next, 'account-view'),
            },
            {
                path: 'account-member',
                component: () => import('@/dashboard/AccountMember.vue'),
                beforeEnter: (to, from, next) => can(next, 'account-member-view'),
            },
            {
                path: 'user',
                component: () => import('@/dashboard/User.vue'),
                beforeEnter: (to, from, next) => can(next, 'user-list'),
            },
            {
                path: 'role',
                component: () => import('@/dashboard/Role.vue'),
                beforeEnter: (to, from, next) => can(next, 'role-list'),
            },
            {
                path: 'setting-general',
                component: () => import('@/dashboard/SettingGeneral.vue'),
                beforeEnter: (to, from, next) => can(next, 'setting-general'),
            },
            {
                path: 'role-dashboard',
                component: () => import('@/dashboard/RoleDashboard.vue'),
                beforeEnter: (to, from, next) => can(next, 'role-dashboard-list'),
            },
            {
                path: 'regional-role',
                component: () => import('@/dashboard/RegionalRole.vue'),
                beforeEnter: (to, from, next) => can(next, 'regional-role-list'),
            },
            {
                path: 'regional-user',
                component: () => import('@/dashboard/RegionalUser.vue'),
                beforeEnter: (to, from, next) => can(next, 'regional-user-list'),
            },
            {
                path: 'document-template',
                component: () => import('@/dashboard/DocumentTemplate.vue'),
                beforeEnter: (to, from, next) => can(next, 'document-template-list'),
            },
            {
                path: 'member',
                component: () => import('@/dashboard/Member.vue'),
                beforeEnter: (to, from, next) => can(next, 'member-list'),
            },
            {
                path: 'member-print-kta',
                component: () => import('@/dashboard/MemberPrintKTA.vue'),
                beforeEnter: (to, from, next) => can(next, 'member-self-print-kta'),
            },
            {
                path: 'kegiatan',
                component: () => import('@/dashboard/Kegiatan.vue'),
                beforeEnter: (to, from, next) => can(next, 'kegiatan-list'),
            },
            {
                path: 'sertifikat',
                component: () => import('@/dashboard/Sertifikat.vue'),
                beforeEnter: (to, from, next) => can(next, 'sertifikat-list'),
            },
            {
                path: 'sertifikat-member',
                component: () => import('@/dashboard/SertifikatMember.vue'),
                beforeEnter: (to, from, next) => can(next, 'member-sertifikat'),
            },
        ],
    },
    {
        path: '/admin',
        redirect: '/dashboard',
        beforeEnter: (to, from, next) => is(next, 'Admin'),
    }
    
];

// if Authenticated
function isAuth(next){
    (store.getters.isAuth) ? next() : next('/login');
}
// if Unauthenticated
function isUnauth(next){
    (store.getters.isAuth) ? next('/dashboard') : next();
}
// checking permission
function can(next, permissions){
    (_can(permissions)) ? next() : next('/dashboard/403');
}
// checking role
function is(next, roles){
    (_is(roles)) ? next() : next('/dashboard/403');
}

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
